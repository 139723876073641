// INDEX
export const INDEX = '/';
export const REQUESTS = '/requests';
export const CODES = '/codes';
export const CERTS = '/certs';
export const LOSSES = '/losses';
export const BROKERAGE = '/brokerage';
export const FEEDBACK = '/feedback';
export const SFTP = '/sftp';

// AUTH
export const LOGIN = '/login';
export const ABOUT = '/about';
export const LOGOUT = '/logout';
export const FILE_RECORDS = '/file';
export const FORGET_PASSWORD = '/forget_password';
export const RESET_PASSWORD = '/reset_password';

// 404
export const NOT_FOUND = '/404';

