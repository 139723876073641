import React, {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Button,
    Col,
    DatePicker,
    Empty,
    Form,
    Layout,
    message,
    Row,
    Skeleton,
    Space,
    Table,
    TablePaginationConfig,
} from 'antd';
import map from 'lodash/map';
import compact from 'lodash/compact';
import {gql, useLazyQuery, useQuery} from '@apollo/client';
import CrmHeader from '../../components/CrmHeader/CrmHeader';
import useQueryParams from '../../hooks/useQueryParams';
import {useNavigate} from 'react-router-dom';
import {LOSSES} from '../../routes';
import NavHeader from '../../components/NavHeader';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {ColumnsType} from 'antd/es/table';
import exportToExcel from './exportToExcel';
import {getLoss, getLossVariables} from "./__generated__/getLoss";

dayjs.extend(isBetween);

const { Content } = Layout;

const { RangePicker } = DatePicker;

const GET_LOSS = gql`
    query getLoss($filters: KzLossFiltersInput, $pagination: PaginationArg = {}) {
        kzLosses(filters: $filters, pagination: $pagination, sort: "createdAt:DESC" ) {
            meta {
                pagination {
                    total
                }
            }
            data {
                id
                attributes {
                    createdAt
                    certNumber
                    certDate
                    name
                    birthDate
                    email
                    message
                }
            }
        }
    }
`;
//
const columns: ColumnsType<TableRowItemData> = [
    {
        title: 'Дата',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => {
            if (!text) {
                return null
            }
            return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },
    },
    {
        title: 'Номер сертификата',
        dataIndex: 'certNumber',
        key: 'certNumber',
    },
    {
        title: 'Дата выдачи сертификата',
        dataIndex: 'certDate',
        key: 'certDate',
        render: (text: string) => {
            if (!text) {
                return null
            }
            return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },
    },
    {
        title: 'Фамилия, Имя',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Дата рождения',
        dataIndex: 'birthDate',
        key: 'birthDate',
        render: (text: string) => {
            if (!text) {
                return null
            }
            return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Причина обращения',
        dataIndex: 'message',
        key: 'message',
    },


];

export interface TableRowItemData {
  key: string,
  createdAt: string,
  certNumber: string,
  certDate: string,
  name: string,
  birthDate: string,
  email: string,
  message: string,
}

interface IFilterFormState {
  range: [dayjs.Dayjs, dayjs.Dayjs] | undefined,
}

const IndexPage: FC = () => {
  const pageSize = 50;
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') || '', 10) || 1;
  const navigate = useNavigate();

  const [form] = Form.useForm<IFilterFormState>();

  const range = Form.useWatch('range', form);

  const { loading, error, data } = useQuery<getLoss, getLossVariables>(
    GET_LOSS,
    {
      variables: {
        filters: {
          createdAt: !!range ? {
            gte: range[0].startOf('day').format(),
            lte: range[1].endOf('day').format(),
          } : {},
        },
        pagination: { page, pageSize },
      },
      skip: !range,
      pollInterval: 5000,
      fetchPolicy: 'no-cache',
    },
  );

  const [getDataToExport] = useLazyQuery<getLoss, getLossVariables>(
    GET_LOSS,
  );

  const [exportPending, setExportPending] = useState(false);

  const onExportHandler = async () => {
    if (!range) {
      return;
    }

    setExportPending(true);
    const dataToExport = await getDataToExport({
      variables: {
        filters: {
          createdAt: {
              gte: range[0].startOf('day').format(),
              lte: range[1].endOf('day').format(),
          },
        },
        pagination: { limit: 500000000 },
      },
    });

    exportToExcel({
      data: dataToExport.data?.kzLosses?.data || [],
      fileName: `Заявки на убыток за период ${range[0].format('DD.MM.YYYY')}-${range[1].format('DD.MM.YYYY')} от ${dayjs().format('DD.MM.YYYY')}`,
    })

    setExportPending(false);
  };

  const total = data?.kzLosses?.meta.pagination.total || 0;

  if (error) {
    void message.error(error.message);
  }

    const dataSource: TableRowItemData[] = compact(map(data?.kzLosses?.data, (item) => {
        if (!item.attributes) {
            return null;
        }

        return {
            key: item.id || '?',
            createdAt: item.attributes?.createdAt,
            certNumber: item.attributes?.certNumber,
            certDate: item.attributes?.certDate,
            name: item.attributes?.name,
            birthDate: item.attributes?.birthDate,
            email: item.attributes?.email,
            message: item.attributes?.message,
        };
    }));

  const onPageChange = (pagination: TablePaginationConfig) => {
    navigate(`${LOSSES}?page=${pagination.current}`);
  };

  const initialFilterFormState: IFilterFormState = useMemo(() => {
    const end = dayjs();
    const start = dayjs().subtract(1, 'month');

    return {
      range: [start, end],
    };
  }, []);

  return (
    <Layout>
        <CrmHeader />
      <Content className="main-content">
        <Row>
          <Col span={24}>
            <NavHeader />
          </Col>
          <Col span={24}>
            {!data && loading && (
              <React.Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space size="large" align='start'>

                <Form initialValues={initialFilterFormState} form={form}>
                <Form.Item name="range">
                  <RangePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                  />
                </Form.Item>
              </Form>
              <Form.Item name="range">
                <Button
                  onClick={onExportHandler}
                  loading={exportPending}
                  disabled={loading || !range || !data?.kzLosses?.data.length}
                >
                  Экспорт в Excel
                </Button>
              </Form.Item>
            </Space>
            <Table
              size="middle"
              loading={loading}
              columns={columns}
              pagination={{
                position: ['bottomLeft'],
                pageSize,
                current: page,
                total,
              }}
              onChange={onPageChange}
              dataSource={loading ? [] : dataSource}
              locale={{
                emptyText: loading ? <Skeleton active={true} /> : <Empty description="нет данных" />,
              }}
            />
          </Col>
        </Row>
      </Content>

    </Layout>
  );
};

export default observer(IndexPage);