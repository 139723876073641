import React, {FC, useMemo, useState} from 'react';
import {Button, Form, InputNumber, message, Modal, Select} from 'antd';
import {gql, useMutation} from "@apollo/client";
import {generateCodes} from "./__generated__/generateCodes";

interface IFormState {
    count: number | undefined,
}

const GENERATE_CODES = gql`
    mutation generateCodes($count: Int!, $program: ENUM_CODE_PROGRAM!) {
        generateCodes(count: $count, program: $program) {
            done
        }
    }
`;

const CodeGenerator: FC = () => {

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [form] = Form.useForm<IFormState>();

    const initialFormState: IFormState = useMemo(() => {
        return {
            count: 10,
        };
    }, []);

    const count = Form.useWatch('count', form);
    const program = Form.useWatch('program', form);

    const [generateCodes, {loading}] = useMutation<generateCodes>(
        GENERATE_CODES,
        {
            variables: {
                count: count,
                program: program,
            },
            onCompleted: (data) => {
                const done = data.generateCodes?.done
                if (done) {
                    form.resetFields()
                    setModalOpen(false)
                } else {
                    message.error('Что-то пошло не так')
                }
            },
        },
    );

    const onFinish = async (values: any) => {
        await generateCodes()
    };

    return (
        <>
            <Button
                type="primary"
                onClick={() => setModalOpen(true)}
            >
                Создать коды
            </Button>

            <Modal title="Создать коды" open={modalOpen} onCancel={() => setModalOpen(false)} footer={<></>}>
                <Form layout={'vertical'} initialValues={initialFormState} form={form} onFinish={onFinish}>
                    <Form.Item name="count" label={'Количество'} required={true}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item name="program" label={'Программа'} required={true}>
                        <Select
                            options={[
                                { value: 'program50', label: '50 000 ₸' },
                                { value: 'program80', label: '80 000 ₸' },
                                { value: 'program150', label: '150 000 ₸' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type={'primary'}
                            htmlType="submit"
                            disabled={loading}
                        >
                            Создать
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
        )

};

export default CodeGenerator;