import XLSX from 'xlsx';
import {getCodes_codes} from './__generated__/getCodes';
import dayjs from 'dayjs';

interface ExportToExcelArgs {
  data: getCodes_codes['data'],
  fileName: string,
}

function exportToExcel(args: ExportToExcelArgs) {
  const {
    fileName,
    data,
  } = args;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([{}]);

  const headers = [
    [
      'Дата создания',
      'Код',
      'Программа',
      'Активирован',
      'Дата активации',
    ],
  ];

  const colsSizes = [
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
    { width: 20 },
  ];

  const rows = data
    .map((item) => {

      let program = ''
      switch (item.attributes?.program) {
        case 'program50': program = '50 000 ₸'; break;
        case 'program80': program = '80 000 ₸'; break;
        case 'program150': program = '150 000 ₸'; break;
      }

      return {
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY'),
        code: item.attributes?.code,
        program: program,
        activated: item.attributes?.activated ? 'Да' : '',
        activated_at: item.attributes?.activated_at ? dayjs(item.attributes?.activated_at).format('DD.MM.YYYY') : '',
      };
    });

  XLSX.utils.sheet_add_json(ws, headers, { origin: 'A1', skipHeader: true });
  XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
  ws['!cols'] = colsSizes;

  XLSX.utils.book_append_sheet(wb, ws, 'Коды');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export default exportToExcel;
