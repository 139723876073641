import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {Menu} from 'antd';
import {KZ} from 'country-flag-icons/react/3x2'
import {BROKERAGE, CERTS, CODES, INDEX, LOSSES, REQUESTS, SFTP} from '../routes';
import {useLocation} from 'react-router';
import './NavHeader.scss'

const NavHeader: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div style={{ paddingBottom: 20 }}>
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={[location.pathname]}>
        <Menu.Item onClick={() => navigate(INDEX)} key={INDEX}>
          Письма
        </Menu.Item>
        <Menu.Item onClick={() => navigate(REQUESTS)} key={REQUESTS}>
          Заявления
        </Menu.Item>
        <Menu.Item onClick={() => navigate(SFTP)} key={SFTP}>
          SFTP
        </Menu.Item>
        <Menu.Item onClick={() => navigate(CODES)} key={CODES}>
          <KZ className={'flag'}/> Коды
        </Menu.Item>
        <Menu.Item onClick={() => navigate(CERTS)} key={CERTS}>
          <KZ className={'flag'}/> Сертификаты
        </Menu.Item>
        <Menu.Item onClick={() => navigate(LOSSES)} key={LOSSES}>
          <KZ className={'flag'}/> Заявления на убыток
        </Menu.Item>
        <Menu.Item onClick={() => navigate(BROKERAGE)} key={BROKERAGE}>
          <KZ className={'flag'}/> Brokerage
        </Menu.Item>
        {/*<Menu.Item onClick={() => navigate(FEEDBACK)} key={FEEDBACK}>*/}
        {/*  <KZ className={'flag'}/> Обратная связь*/}
        {/*</Menu.Item>*/}

      </Menu>
    </div>
  );
};

export default NavHeader;
