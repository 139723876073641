import {ENUM_REFUNDREQUEST_STATUS} from './__generated__/global-types';

export const statusNamesMap = {
  [ENUM_REFUNDREQUEST_STATUS.IN_GEN_QUEUE_1]: 'В очереди на генерацию PDF',
  [ENUM_REFUNDREQUEST_STATUS.READY_TO_SEND_2]: 'В очереди на отправку',
  [ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3]: 'Отправлено клиенту',
  [ENUM_REFUNDREQUEST_STATUS.RECEIVE_SIGN_4]: 'Получили подписанное клиентом заявление',
  [ENUM_REFUNDREQUEST_STATUS.REFUND_DONE_5]: 'Произведён возврат',
  [ENUM_REFUNDREQUEST_STATUS.REJECT_6]: 'Отказ',
  [ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7]: 'Ошибка генерации',
}


// 1 получили (в очереди на генерацию) -> 3
// 2 сгенерировали -> 3
// 3 отправили клиенту -> 4
// 4 получили подписанный клиентом -> 3, 5, 6
// 5 произведён возврат -> fin
// 6 отказ -> fin
// 7 ошибка генерации -> 3
// 8 отказ (не верный номер сертификата) -> fin
// 9 отказ (возврат через офис) -> fin
export const allowedStatusFlowFromStatus = {
  [ENUM_REFUNDREQUEST_STATUS.IN_GEN_QUEUE_1]: [
    ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3
  ],
  [ENUM_REFUNDREQUEST_STATUS.READY_TO_SEND_2]: [
    ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3
  ],
  [ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3]: [
    ENUM_REFUNDREQUEST_STATUS.RECEIVE_SIGN_4
  ],
  [ENUM_REFUNDREQUEST_STATUS.RECEIVE_SIGN_4]: [
    ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3,
    ENUM_REFUNDREQUEST_STATUS.REFUND_DONE_5,
    ENUM_REFUNDREQUEST_STATUS.REJECT_6
  ],
  [ENUM_REFUNDREQUEST_STATUS.REFUND_DONE_5]: [],
  [ENUM_REFUNDREQUEST_STATUS.REJECT_6]: [],
  [ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7]: [
    ENUM_REFUNDREQUEST_STATUS.SENDED_TO_CLIENT_3
  ],
}

//   FIO: string,
//   Passport: string,
//   Phone: string,
//   Email: string,
//   Certificate_Number: string,
//   Certificate_Date: string,
//   Bank_Name: string,
//   Bank_Correspondent: string,
//   Bank_Number: string,
//   Bank_Account: string,

export const requestDataFieldNames = {
  FIO: 'ФИО',
  Passport: 'Серия и номер паспорта',
  Phone: 'Телефон',
  Email: 'E-mail',
  Certificate_Number: 'Номер сертификата',
  Certificate_Date: 'Дата выдачи сертификата',
  Bank_Name: 'Наименование банка',
  Bank_Correspondent: 'Корр. счет',
  Bank_Number: 'БИК',
  Bank_Account: 'Номер счета получателя',
}
