import XLSX from 'xlsx';
import {getLoss_kzLosses} from './__generated__/getLoss';
import dayjs from 'dayjs';

interface ExportToExcelArgs {
  data: getLoss_kzLosses['data'],
  fileName: string,
}

function exportToExcel(args: ExportToExcelArgs) {
  const {
    fileName,
    data,
  } = args;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([{}]);

  const headers = [
    [
      'Дата создания',
      'Номер сертификата',
      'Дата выдачи сертификата',
      'Фамилия, Имя',
      'Дата рождения',
      'Email',
      'Причина обращения',
    ],
  ];

  const colsSizes = [
    { width: 15 },
    { width: 25 },
    { width: 15 },
    { width: 30 },
    { width: 15 },
    { width: 20 },
    { width: 40 },
  ];

  const rows = data
    .map((item) => {

      return {
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY'),
        certNumber: item.attributes?.certNumber,
        certDate: dayjs(item.attributes?.certDate).format('DD.MM.YYYY'),
        name: item.attributes?.name,
        birthDate: dayjs(item.attributes?.birthDate).format('DD.MM.YYYY'),
        email: item.attributes?.email,
        message: item.attributes?.message,
      };
    });

  XLSX.utils.sheet_add_json(ws, headers, { origin: 'A1', skipHeader: true });
  XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
  ws['!cols'] = colsSizes;

  XLSX.utils.book_append_sheet(wb, ws, 'Коды');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export default exportToExcel;
