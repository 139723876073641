import React, {FC, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Descriptions,
  Empty,
  Form,
  Layout,
  message,
  Row,
  Skeleton,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from 'antd';
import map from 'lodash/map';
import compact from 'lodash/compact';
import {gql, useLazyQuery, useQuery} from '@apollo/client';
import CrmHeader from '../../components/CrmHeader/CrmHeader';
import useQueryParams from '../../hooks/useQueryParams';
import {getRequests, getRequestsVariables} from './__generated__/getRequests';
import {ENUM_REFUNDREQUEST_STATUS} from '../../__generated__/global-types';
import {useNavigate} from 'react-router-dom';
import {REQUESTS} from '../../routes';
import NavHeader from '../../components/NavHeader';
import dayjs from 'dayjs';
import {getApiBase} from '../../env';
import {ColumnsType} from 'antd/es/table';
import {allowedStatusFlowFromStatus, requestDataFieldNames, statusNamesMap} from '../../dataMappers';
import StatusChanger from './StatusChanger';
import exportToExcel from './exportToExcel';

const { Paragraph } = Typography;

const { Content } = Layout;

const { RangePicker } = DatePicker;

const GET_REQUESTS = gql`
    query getRequests($filters: RefundRequestFiltersInput, $pagination: PaginationArg = {}) {
        refundRequests(filters: $filters, pagination: $pagination, sort: "id:DESC" ) {
            meta {
                pagination {
                    total
                }
            }
            data {
                id
                attributes {
                    createdAt
                    requestData
                    requestPdfFileUrl
                    status
                    validationErrorMessage
                }
            }
        }
    }
`;

const columns: ColumnsType<TableRowItemData> = [
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string) => {
      if (!text) {
        return null;
      }

      return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
  },
  {
    title: 'Файл заявления',
    dataIndex: 'requestPdfFileUrl',
    key: 'requestPdfFileUrl',
    render: (value: string, row) => {
      if (!value) {
        return 'нет';
      }

      return (
        <a href={`${getApiBase()}/uploads/pdf/${value}`} target="_blank" rel="noreferrer">скачать</a>
      );
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status: ENUM_REFUNDREQUEST_STATUS, row) => {
      const toStatuses = allowedStatusFlowFromStatus[status];

      if (toStatuses.length === 0) {
        return statusNamesMap[status];
      }

      return <StatusChanger status={status} id={row.key} />;
    },
  },
];

export interface RequestData {
  FIO: string,
  Passport: string,
  Phone: string,
  Email: string,
  Certificate_Number: string,
  Certificate_Date: string,
  Bank_Name: string,
  Bank_Correspondent: string,
  Bank_Number: string,
  Bank_Account: string,
}

export interface TableRowItemData {
  key: string,
  createdAt: string,
  requestPdfFileUrl?: string,
  status: ENUM_REFUNDREQUEST_STATUS,
  requestData: RequestData,
  validationErrorMessage?: string
}

interface IFilterFormState {
  range: [dayjs.Dayjs, dayjs.Dayjs] | undefined,
}

const IndexPage: FC = () => {
  const pageSize = 50;
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') || '', 10) || 1;
  const navigate = useNavigate();

  const [form] = Form.useForm<IFilterFormState>();

  const range = Form.useWatch('range', form);

  const { loading, error, data } = useQuery<getRequests, getRequestsVariables>(
    GET_REQUESTS,
    {
      variables: {
        filters: {
          createdAt: !!range ? {
            gte: range[0].format(),
            lte: range[1].format(),
          } : {},
        },
        pagination: { page, pageSize }
      },
      skip: !range,
      pollInterval: 5000,
    },
  );

  const [getDataToExport] = useLazyQuery<getRequests, getRequestsVariables>(
    GET_REQUESTS,
  );

  const [exportPending, setExportPending] = useState(false);

  const onExportHandler = async () => {
    if (!range) {
      return;
    }

    setExportPending(true);
    console.log('export');
    const dataToExport = await getDataToExport({
      variables: {
        filters: {
          createdAt: {
            gte: range[0].format(),
            lte: range[1].format(),
          },
        },
        pagination: { limit: 500000000 },
      },
    });

    exportToExcel({
      data: dataToExport.data?.refundRequests?.data || [],
      fileName: `Заявления за период ${range[0].format('DD.MM.YYYY')}-${range[1].format('DD.MM.YYYY')} от ${dayjs().format('DD.MM.YYYY')}`,
    })

    setExportPending(false);
  };

  const total = data?.refundRequests?.meta.pagination.total || 0;

  if (error) {
    void message.error(error.message);
  }

  const dataSource: TableRowItemData[] = compact(map(data?.refundRequests?.data, (item) => {
    if (!item.attributes) {
      return null;
    }

    return {
      key: item.id || '?',
      createdAt: item.attributes?.createdAt,
      requestPdfFileUrl: item.attributes?.requestPdfFileUrl || undefined,
      status: item.attributes?.status || ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7,
      requestData: item.attributes?.requestData as RequestData,
      validationErrorMessage: item.attributes.status === ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7 && item.attributes.validationErrorMessage ? item.attributes.validationErrorMessage : undefined,
    };
  }));

  const onPageChange = (pagination: TablePaginationConfig) => {
    navigate(`${REQUESTS}/?page=${pagination.current}`);
  };

  const initialFilterFormState: IFilterFormState = useMemo(() => {
    const end = dayjs();
    const start = dayjs().subtract(1, 'month');

    return {
      range: [start, end],
    };
  }, []);

  return (
    <Layout>
      <CrmHeader />
      <Content className="main-content">
        <Row>
          <Col span={24}>
            <NavHeader />
          </Col>
          <Col span={24}>
            {!data && loading && (
              <React.Fragment>
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
                <Skeleton.Input />
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space size="large">
              <Form initialValues={initialFilterFormState} form={form}>
                <Form.Item name="range">
                  <RangePicker
                    allowClear={false}
                    format="DD.MM.YYYY"
                  />
                </Form.Item>
              </Form>
              <Form.Item name="range">
                <Button
                  type="primary"
                  onClick={onExportHandler}
                  loading={exportPending}
                  disabled={loading || !range || !data?.refundRequests?.data.length}
                >
                  Экспорт в Excel
                </Button>
              </Form.Item>
            </Space>
            <Table
              size="middle"
              loading={loading}
              columns={columns}
              pagination={{
                position: ['bottomLeft'],
                pageSize,
                current: page,
                total,
              }}
              onChange={onPageChange}
              dataSource={loading ? [] : dataSource}
              locale={{
                emptyText: loading ? <Skeleton active={true} /> : <Empty description="нет данных" />,
              }}
              expandable={{
                expandedRowRender: record => (
                  <Paragraph
                    copyable={{
                      text: Object.keys(record.requestData).map((key) => {
                        // @ts-ignore
                        return `${requestDataFieldNames[key]}: ${record.requestData[key]}`;
                      }).join('\n'),
                    }}
                  >
                    {!!record.validationErrorMessage && (
                      <>
                        <Alert
                          message="Сообщение об ошибке"
                          description={record.validationErrorMessage}
                          type="error"
                        />
                        <br />
                        <br />
                      </>
                    )}
                    <Descriptions
                      column={1}
                      bordered
                      title="Данные заявления"
                      size="small"
                    >
                      {Object.keys(record.requestData).map((key) => (
                        // @ts-ignore
                        <Descriptions.Item label={requestDataFieldNames[key]} key={key}>
                          {/* @ts-ignore*/}
                          {record.requestData[key]}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </Paragraph>
                ),
                rowExpandable: record => !!record.requestData,
              }}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default observer(IndexPage);



