// ISSUED
// REFUND
// CANCELLED
export const statusNamesMap = {
  CALCULATED: 'Рассчитан',
  ISSUED: 'Выпущен',
  REFUND: 'Возвращен',
  CANCELLED: 'Отменен',
}

// IN_GEN_QUEUE_1
// GENERATED_2
// SENT_3
// ERROR_4
export const sendStatusNamesMap = {
  IN_GEN_QUEUE_1: 'В очереди на генерацию',
  GENERATED_2: 'В очереди на отправку',
  SENT_3: 'Отправлен',
  ERROR_4: 'Ошибка',
}

// name: string;
// IIN: string;
// date: any;
// amount: number;
// period: number;
// phone: string;
// certNumber: string;
// status: ENUM_BROKERAGECERT_STATUS;
// sendStatus: ENUM_BROKERAGECERT_SENDSTATUS;
// pdfFileName: string | null;
// errorMessage: string | null;

export const dataFieldNames = {
  name: 'Фамилия Имя',
  IIN: 'ИИН',
  date: 'Дата',
  amount: 'Сумма займа',
  period: 'Период займа',
  fare: 'Тариф',
  phone: 'Телефон',
  certNumber: 'Номер сертификата',
  status: 'Статус',
  sendStatus: 'Статус отправки',
  pdfFileName: 'PDF файл',
  errorMessage: 'Ошибка',
}
