import React, {FC, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Col, Empty, Layout, message, Row, Skeleton, Table, TablePaginationConfig, Typography} from 'antd';
import map from 'lodash/map';
import keys from 'lodash/keys';
import dayjs from 'dayjs';
import cn from 'classnames';
import {gql, useQuery} from '@apollo/client';
import CrmHeader from '../../components/CrmHeader/CrmHeader';
import {useNavigate, useParams} from 'react-router-dom';
import {FILE_RECORDS, INDEX} from '../../routes';
import {getEmail, getEmailVariables} from './__generated__/getEmail';
import {getApiBase} from '../../env';
import {ENUM_RECORD_SENDSTATUS} from '../../__generated__/global-types';
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {FilterValue} from 'antd/lib/table/interface';
import useQueryParams from '../../hooks/useQueryParams';


const { Content } = Layout;

const GET_EMAIL = gql`
    query getEmail($id: ID!, $page: Int!, $pageSize: Int!) {
        emailToParse(id: $id ) {
            data {
                id
                attributes {
                    filename
                    createdAt
                    status
                    originalFilename
                }
            }
        }
        records(
            pagination: {page: $page, pageSize: $pageSize},
            filters: { source: {id: {eq: $id}}}
            sort: [
                "errorMessage:desc",
                "sendStatus:asc"
            ]
        ) {
            meta {
                pagination {
                    total
                }
            }
            data {
                id
                attributes {
                    sendStatus
                    birthday
                    certNumber
                    createdAt
                    email
                    errorMessage
                    fio
                    isValid
                    issueDate
                    passport
                    serviceNumber
                    pdfFilename
                    phone
                    premium
                    sendStatus
                    sumInsured
                    updatedAt
                    validity
                    yupError
                }
            }
        }
    }
`;

const columns = [
  {
    title: 'Номер сертификата',
    dataIndex: 'certNumber',
    key: 'certNumber',
  },
  {
    title: 'Дата выдачи',
    dataIndex: 'issueDate',
    key: 'issueDate',
    render: (text: string) => {
      if (!text) {
        return null;
      }

      return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
  },
  {
    title: 'Срок действия',
    dataIndex: 'validity',
    key: 'validity',
    render: (text: string) => {
      if (!text) {
        return null;
      }

      return `${text} мес.`;
    },
  },
  {
    title: 'ФИО',
    dataIndex: 'fio',
    key: 'fio',
  },
  {
    title: 'Дата рождения',
    dataIndex: 'birthday',
    key: 'birthday',
    render: (text: string) => {
      if (!text) {
        return null;
      }

      return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
  },
  {
    title: 'Паспорт, серия номер',
    dataIndex: 'passport',
    key: 'passport',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Страховая сумма, руб.',
    dataIndex: 'sumInsured',
    key: 'sumInsured',
  },
  {
    title: 'Страховая премия, руб.',
    dataIndex: 'premium',
    key: 'premium',
  },
  {
    title: 'Кол-во консультаций',
    dataIndex: 'serviceNumber',
    key: 'serviceNumber',
  },
  {
    title: 'Файл сертификата',
    dataIndex: 'pdfFilename',
    key: 'pdfFilename',
    render: (text: string) => {
      if (!text) {
        return 'нет';
      }

      return <a href={`${getApiBase()}/uploads/pdf/${text}`} target="_blank" rel="noreferrer">скачать</a>;
    },
  },
  {
    title: 'Статус',
    dataIndex: 'result',
    key: 'result',
    render: (result: IResult) => {
      return (
        <div className={cn([
          'result-cell',
          result.success && 'result-cell__success',
          result.error && 'result-cell__error',
          result.pending && 'result-cell__pending',
        ])}>
          <div className="icon">
            {result.success && (
              <CheckCircleOutlined/>
            )}
            {result.error && (
              <CloseCircleOutlined/>
            )}
            {result.pending && (
              <ClockCircleOutlined/>
            )}
          </div>
          <div className="message" style={{ whiteSpace: 'pre-line' }}>
            {result.message}
          </div>
        </div>
      );
    },
    // filters: [
    //   { text: 'Успех', value: 'success' },
    //   { text: 'Ожидание', value: 'pending' },
    //   { text: 'Неудача', value: 'error' },
    // ],
  },
];

type RouteParams = {
  id?: string,
  filters: string,
}

interface IResult {
  error: boolean,
  success: boolean,
  pending: boolean,
  message: string
}

const getFilters = (filtersString: string) => {
  if (!filtersString) {
    return {};
  }

  try {
    return JSON.parse(filtersString);
  } catch (e) {
    console.error(e);
    return {};
  }
};

const FileRecordsPage: FC = () => {
  const navigate = useNavigate();

  const params = useParams<RouteParams>();

  const pageSize = 100;
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') || '', 10) || 1;

  const id = params.id || '';
  const filtersString = queryParams.get('filters') || '';

  const filters = getFilters(filtersString);

  useEffect(() => {
    if (!id) {
      navigate(INDEX, { replace: true });
    }
  }, [ id, navigate ]);

  const { loading, error, data } = useQuery<getEmail, getEmailVariables>(
    GET_EMAIL,
    { variables: { id, page, pageSize } },
  );

  if (error) {
    void message.error(error.message);
  }

  const total = data?.records?.meta?.pagination?.total || 0;

  const dataSource = map(data?.records?.data, (item) => {
    const attr = item.attributes;

    const isValid = !!attr?.isValid;
    const isError = !!attr?.errorMessage || attr?.sendStatus === ENUM_RECORD_SENDSTATUS.FAIL || !isValid;
    const isDelivered = !isError && attr?.sendStatus === ENUM_RECORD_SENDSTATUS.DELIVERED;
    const isPending = !isError && !isDelivered;
    const isPendingSend = attr?.sendStatus === ENUM_RECORD_SENDSTATUS.NEED_SEND;
    const isPendingStatus = attr?.sendStatus === ENUM_RECORD_SENDSTATUS.PENDING_STATUS;
    const isPendingPdf = !attr?.pdfFilename && !isError;

    let message = '';
    if (isError) {
      message = attr?.errorMessage || 'Неизвестная ошибка';
    } else if (isDelivered) {
      message = 'Доставлено';
    } else if (isPendingPdf) {
      message = 'В очереди на генерацию сертификата';
    } else if (isPendingSend) {
      message = 'В очереди на отправку';
    } else if (isPendingStatus) {
      message = 'Отправлено';
    }

    const result: IResult = {
      error: isError,
      success: isDelivered,
      pending: isPending,
      message,
    };

    return {
      key: item.id || '?',
      ...attr,
      result,
    };
  });

  console.log('data', dataSource[0]);

  const onPageChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    if (!filters || keys(filters).length === 0) {
      navigate(`${FILE_RECORDS}/${id}/?page=${pagination.current}`);
      return;
    }

    const url = `${FILE_RECORDS}/${id}/?page=${pagination.current}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
    navigate(url);
  };

  const attrs = data?.emailToParse?.data?.attributes;

  const originalFilename = attrs?.originalFilename || '';
  const createdAt = attrs?.createdAt ? dayjs(attrs?.createdAt).format('DD.MM.YYYY') : '';

  const columnsWithFilter = useMemo(() => {
    if (!!filters && filters.result) {
      return map(columns, (col) => {
        if (col.key === 'result') {
          return {
            ...col,
            defaultFilteredValue: filters.result,
          };
        } else {
          return col;
        }
      });
    } else {
      return columns;
    }
  }, [ filters ]);

  return (
    <Layout>
      <CrmHeader/>
      <Content className="main-content">
        <Row>
          <Col span={24}>
            <Row>
              <Col md={6} sm={12} xs={24}>
                <Typography.Title level={2}>
                  {createdAt}
                </Typography.Title>
              </Col>
              <Col md={18} sm={12} xs={24}>
                <Typography.Title level={4}>
                  {originalFilename}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {!data && loading && (
              <React.Fragment>
                <Skeleton.Input/>
                <Skeleton.Input/>
                <Skeleton.Input/>
                <Skeleton.Input/>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              size="small"
              loading={loading}
              columns={columnsWithFilter}
              pagination={{
                position: [ 'bottomLeft' ],
                pageSize,
                current: page,
                total,
                pageSizeOptions: [100, 500, 1000]
              }}
              onChange={onPageChange}
              dataSource={loading ? [] : dataSource}
              locale={{
                emptyText: loading ? <Skeleton active={true}/> : <Empty description="нет данных"/>,
              }}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default observer(FileRecordsPage);



