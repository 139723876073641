import React, {FC} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import ru_RU from 'antd/lib/locale/ru_RU';
import {ConfigProvider} from 'antd';
import {provider, useInstance} from 'react-ioc';
import {observer} from 'mobx-react-lite';
import {ApolloClient, ApolloProvider} from '@apollo/client';

import './App.scss';

import StoreDI from './model/store/StoreDI';
import ApolloClientDI from './model/graphql/ApolloClientDI';
import {Store} from './model/store/Store';
import ScrollToTop from './components/common/ScrollToTop';
import {
  BROKERAGE,
  CERTS,
  CODES,
  FILE_RECORDS,
  FORGET_PASSWORD,
  INDEX,
  LOGIN,
  LOGOUT, LOSSES,
  NOT_FOUND,
  REQUESTS,
  RESET_PASSWORD, SFTP
} from './routes';
import NotFound from './pages/NotFound';
import LoginPage from './pages/Auth/LoginPage';
import ForgetPasswordPage from './pages/Auth/ForgetPasswordPage';
import IndexPage from './pages/main/IndexPage';
import LogoutPage from './pages/Auth/LogoutPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import FileRecordsPage from './pages/main/FileRecordsPage';
import RequestsPage from './pages/requests/RequestsPage';
import CodesPage from './pages/code/CodesPage';
import SftpPage from './pages/sftp/SftpPage';

import 'dayjs/locale/ru';
import LossPage from "./pages/loss/LossPage";
import CertsPage from "./pages/cert/CertsPage";
import BrokeragePage from "./pages/brokerage/BrokeragePage";

// function getWindowDimensions() {
//   const {innerWidth: width, innerHeight: height} = window;
//   return {
//     width,
//     heights
//   };
// }


const App: FC = () => {
  const store = useInstance(Store);
  const { hydrated, auth } = store;
  const { hasAuthToken } = auth;
  const apolloClient = useInstance(ApolloClient);


  if (!hydrated) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <ConfigProvider locale={ru_RU}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <ScrollToTop />
          <Routes>
            <React.Fragment>
              {!hasAuthToken && (
                <React.Fragment>
                  <Route path={LOGIN} element={<LoginPage />} />
                  <Route path={FORGET_PASSWORD} element={<ForgetPasswordPage />} />
                  <Route path={RESET_PASSWORD} element={<ResetPasswordPage />} />
                  <Route path="*" element={<Navigate to={LOGIN} />} />
                </React.Fragment>
              )}

              {hasAuthToken && (
                <React.Fragment>
                  <Route path={INDEX} element={<IndexPage />} />
                  <Route path={`${FILE_RECORDS}/:id`} element={<FileRecordsPage />} />
                  <Route path={LOGOUT} element={<LogoutPage />} />
                  <Route path="*" element={<Navigate to={INDEX} />} />
                  <Route path={REQUESTS} element={<RequestsPage />} />
                  <Route path={CODES} element={<CodesPage />} />
                  <Route path={CERTS} element={<CertsPage />} />
                  <Route path={LOSSES} element={<LossPage />} />
                  <Route path={BROKERAGE} element={<BrokeragePage />} />
                  <Route path={SFTP} element={<SftpPage />} />
                  <Route path={NOT_FOUND} element={<NotFound />} />
                </React.Fragment>
              )}
            </React.Fragment>
          </Routes>
        </Router>
      </ApolloProvider>
    </ConfigProvider>
  );
};

export default provider(
  StoreDI(),
  ApolloClientDI(),
)(observer(App));
