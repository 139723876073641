import XLSX from 'xlsx';
import dayjs from 'dayjs';
import {brokerageCerts_brokerageCerts} from "./__generated__/brokerageCerts";
import {statusNamesMap, sendStatusNamesMap, dataFieldNames} from "./dataMappers"

interface ExportToExcelArgs {
  data: brokerageCerts_brokerageCerts['data'],
  fileName: string,
}

function exportToExcel(args: ExportToExcelArgs) {
  const {
    fileName,
    data,
  } = args;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([{}]);

  const headers = [
    [
      dataFieldNames.date,
      dataFieldNames.certNumber,
      dataFieldNames.name,
      dataFieldNames.IIN,
      dataFieldNames.amount,
      dataFieldNames.period,
      dataFieldNames.fare,
      dataFieldNames.phone,
      dataFieldNames.pdfFileName,
      dataFieldNames.status,
      dataFieldNames.sendStatus,
      dataFieldNames.errorMessage,
    ],
  ];

  const colsSizes = [
    { width: 15 },
    { width: 20 },
    { width: 30 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 15 },
    { width: 20 },
    { width: 20 },
    { width: 15 },
    { width: 20 },
    { width: 30 },
  ];

  const rows = data
    .map((item) => {

      let status = ''
      switch (item.attributes?.status) {
        case 'CALCULATED': status = statusNamesMap.CALCULATED; break;
        case 'ISSUED': status = statusNamesMap.ISSUED; break;
        case 'REFUND': status = statusNamesMap.REFUND; break;
        case 'CANCELLED': status = statusNamesMap.CANCELLED; break;
      }

      let sendStatus = ''
      switch (item.attributes?.sendStatus) {
        case 'IN_GEN_QUEUE_1': sendStatus = sendStatusNamesMap.IN_GEN_QUEUE_1; break;
        case 'GENERATED_2': sendStatus = sendStatusNamesMap.GENERATED_2; break;
        case 'SENT_3': sendStatus = sendStatusNamesMap.SENT_3; break;
        case 'ERROR_4': sendStatus = sendStatusNamesMap.ERROR_4; break;
      }

      return {
        date: dayjs(item.attributes?.date).format('DD.MM.YYYY'),
        certNumber: item.attributes?.certNumber,
        name: item.attributes?.name,
        IIN: item.attributes?.IIN,
        amount: item.attributes?.amount,
        period: item.attributes?.period,
        fare: item.attributes?.fare,
        phone: item.attributes?.phone,
        pdfFileUrl: item.attributes?.pdfFileName,
        status,
        sendStatus,
        errorMessage: item.attributes?.errorMessage,
      };
    });

  XLSX.utils.sheet_add_json(ws, headers, { origin: 'A1', skipHeader: true });
  XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
  ws['!cols'] = colsSizes;

  XLSX.utils.book_append_sheet(wb, ws, 'Коды');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export default exportToExcel;
