import React, {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Col, Empty, Form, Layout, message, Row, Skeleton, Space, Table,} from 'antd';
import map from 'lodash/map';
import compact from 'lodash/compact';
import {gql, useMutation, useQuery} from '@apollo/client';
import CrmHeader from '../../components/CrmHeader/CrmHeader';
import {getSFTPFiles} from './__generated__/getSFTPFiles';
import NavHeader from '../../components/NavHeader';
import dayjs from 'dayjs';
import {ColumnsType} from 'antd/es/table';

import formatBytes from './formatBytes'
import {runCommand} from "./__generated__/runCommand";
import {getSFTPStatus} from "./__generated__/getSFTPStatus";
import {Link} from "react-router-dom";
import {getSftpUrlBase} from "../../env";

const {Content} = Layout;

const GET_FILES = gql`
    query getSFTPFiles {
        getSFTPFiles {
            name
            date
            size
        }
    }
`;

const GET_STATUS = gql`
    query getSFTPStatus {
        getSFTPStatus {
            count
        }
    }
`;

const RUN_COMMAND = gql`
    mutation runCommand {
        runSFTPCommand {
            done
        }
    }
`;
//
const columns: ColumnsType<TableRowItemData> = [
    {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: (text: dayjs.Dayjs) => {
            if (!text) {
                return null
            }
            return dayjs(text, 'YYYY-MM-DD').format('DD.MM.YYYY');
        },
        sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
        defaultSortOrder: 'descend'
    },
    {
        title: 'Файл',
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => {
            const url = `${getSftpUrlBase()}/${text}`
            return <Link to={url}>{text}</Link>
        }
    },
    {
        title: 'Размер',
        dataIndex: 'size',
        key: 'size',
        render: (size: number) => {
            return formatBytes(size)
        }
    },
];

export interface TableRowItemData {
    key: number,
    date: dayjs.Dayjs,
    name: string,
    size: number,
}

const IndexPage: FC = () => {

    const [count, setCount] = useState<number>(0)

    useQuery<getSFTPStatus>(
        GET_STATUS,
        {
            pollInterval: 5000,
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                const count = data.getSFTPStatus?.count || 0
                setCount(count)
            }
        },
    );

    const {loading, error, data} = useQuery<getSFTPFiles>(
        GET_FILES,
        {
            pollInterval: 5000,
            fetchPolicy: 'no-cache',
        },
    );

    const [runCommand] = useMutation<runCommand>(RUN_COMMAND, {
        onCompleted: (data) => {
            const done = data.runSFTPCommand?.done
            if (done) {
                setCheckPending(false)
            }
        }
    })

    if (error) {
        void message.error(error.message);
    }

    const dataSource: TableRowItemData[] = compact(map(data?.getSFTPFiles, (item, index) => {
        return {
            key: index,
            date: dayjs(item?.date),
            name: item?.name || '',
            size: item?.size || 0,
        };
    }));

    const [checkPending, setCheckPending] = useState(false);

    const onCheckHandler = async () => {
        setCheckPending(true)
        await runCommand()
    }

    return (
        <Layout>
            <CrmHeader/>
            <Content className="main-content">
                <Row>
                    <Col span={24}>
                        <NavHeader/>
                    </Col>
                    <Col span={24}>
                        {!data && loading && (
                            <React.Fragment>
                                <Skeleton.Input/>
                                <Skeleton.Input/>
                                <Skeleton.Input/>
                                <Skeleton.Input/>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Space size="large" align='start'>

                            <Form.Item>
                                <Button
                                    onClick={onCheckHandler}
                                    type={'primary'}
                                    loading={checkPending}
                                >
                                    Проверить файлы
                                </Button>
                            </Form.Item>
                            <span style={{lineHeight: '32px'}}>Файлов в обработке: {count}</span>


                        </Space>
                        <Table
                            size="middle"
                            loading={loading}
                            columns={columns}
                            pagination={{
                                position: ['bottomLeft'],
                            }}
                            dataSource={loading ? [] : dataSource}
                            locale={{
                                emptyText: loading ? <Skeleton active={true}/> : <Empty description="нет данных"/>,
                            }}
                        />
                    </Col>
                </Row>
            </Content>

        </Layout>
    );
};

export default observer(IndexPage);