/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ENUM_BROKERAGECERT_SENDSTATUS {
  ERROR_4 = "ERROR_4",
  GENERATED_2 = "GENERATED_2",
  IN_GEN_QUEUE_1 = "IN_GEN_QUEUE_1",
  SENT_3 = "SENT_3",
}

export enum ENUM_BROKERAGECERT_STATUS {
  CALCULATED = "CALCULATED",
  CANCELLED = "CANCELLED",
  ISSUED = "ISSUED",
  REFUND = "REFUND",
}

export enum ENUM_CODE_PROGRAM {
  program150 = "program150",
  program50 = "program50",
  program80 = "program80",
}

export enum ENUM_EMAILTOPARSE_STATUS {
  AWAITING_PARSE = "AWAITING_PARSE",
  PARSE_DONE = "PARSE_DONE",
  PARSE_IN_WORK = "PARSE_IN_WORK",
}

export enum ENUM_KZCERT_PROGRAM {
  program150 = "program150",
  program50 = "program50",
  program80 = "program80",
}

export enum ENUM_KZCERT_STATUS {
  GENERATED_2 = "GENERATED_2",
  GEN_ERROR_4 = "GEN_ERROR_4",
  IN_GEN_QUEUE_1 = "IN_GEN_QUEUE_1",
  SENDED_TO_CLIENT_3 = "SENDED_TO_CLIENT_3",
}

export enum ENUM_RECORD_SENDSTATUS {
  DELIVERED = "DELIVERED",
  FAIL = "FAIL",
  NEED_SEND = "NEED_SEND",
  PENDING_STATUS = "PENDING_STATUS",
}

export enum ENUM_REFUNDREQUEST_STATUS {
  GEN_ERROR_7 = "GEN_ERROR_7",
  IN_GEN_QUEUE_1 = "IN_GEN_QUEUE_1",
  READY_TO_SEND_2 = "READY_TO_SEND_2",
  RECEIVE_SIGN_4 = "RECEIVE_SIGN_4",
  REFUND_DONE_5 = "REFUND_DONE_5",
  REJECT_6 = "REJECT_6",
  SENDED_TO_CLIENT_3 = "SENDED_TO_CLIENT_3",
}

export interface BooleanFilterInput {
  and?: (boolean | null)[] | null;
  or?: (boolean | null)[] | null;
  not?: BooleanFilterInput | null;
  eq?: boolean | null;
  ne?: boolean | null;
  startsWith?: boolean | null;
  endsWith?: boolean | null;
  contains?: boolean | null;
  notContains?: boolean | null;
  containsi?: boolean | null;
  notContainsi?: boolean | null;
  gt?: boolean | null;
  gte?: boolean | null;
  lt?: boolean | null;
  lte?: boolean | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (boolean | null)[] | null;
  notIn?: (boolean | null)[] | null;
  between?: (boolean | null)[] | null;
}

export interface BrokerageCertFiltersInput {
  id?: IDFilterInput | null;
  name?: StringFilterInput | null;
  IIN?: StringFilterInput | null;
  date?: DateFilterInput | null;
  amount?: FloatFilterInput | null;
  period?: IntFilterInput | null;
  phone?: StringFilterInput | null;
  certNumber?: StringFilterInput | null;
  status?: StringFilterInput | null;
  sendStatus?: StringFilterInput | null;
  pdfFileName?: StringFilterInput | null;
  errorMessage?: StringFilterInput | null;
  fare?: FloatFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (BrokerageCertFiltersInput | null)[] | null;
  or?: (BrokerageCertFiltersInput | null)[] | null;
  not?: BrokerageCertFiltersInput | null;
}

export interface CodeFiltersInput {
  id?: IDFilterInput | null;
  UID?: StringFilterInput | null;
  code?: StringFilterInput | null;
  activated?: BooleanFilterInput | null;
  activated_at?: DateTimeFilterInput | null;
  activated_by?: JSONFilterInput | null;
  program?: StringFilterInput | null;
  kz_cert?: KzCertFiltersInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (CodeFiltersInput | null)[] | null;
  or?: (CodeFiltersInput | null)[] | null;
  not?: CodeFiltersInput | null;
}

export interface DateFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateFilterInput | null;
  eq?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface DateTimeFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: DateTimeFilterInput | null;
  eq?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface FloatFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: FloatFilterInput | null;
  eq?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface IDFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: IDFilterInput | null;
  eq?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface IntFilterInput {
  and?: (number | null)[] | null;
  or?: (number | null)[] | null;
  not?: IntFilterInput | null;
  eq?: number | null;
  ne?: number | null;
  startsWith?: number | null;
  endsWith?: number | null;
  contains?: number | null;
  notContains?: number | null;
  containsi?: number | null;
  notContainsi?: number | null;
  gt?: number | null;
  gte?: number | null;
  lt?: number | null;
  lte?: number | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (number | null)[] | null;
  notIn?: (number | null)[] | null;
  between?: (number | null)[] | null;
}

export interface JSONFilterInput {
  and?: (any | null)[] | null;
  or?: (any | null)[] | null;
  not?: JSONFilterInput | null;
  eq?: any | null;
  ne?: any | null;
  startsWith?: any | null;
  endsWith?: any | null;
  contains?: any | null;
  notContains?: any | null;
  containsi?: any | null;
  notContainsi?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (any | null)[] | null;
  notIn?: (any | null)[] | null;
  between?: (any | null)[] | null;
}

export interface KzCertFiltersInput {
  id?: IDFilterInput | null;
  number?: StringFilterInput | null;
  name?: StringFilterInput | null;
  passport?: StringFilterInput | null;
  birthDate?: DateFilterInput | null;
  activatedAt?: DateFilterInput | null;
  phone?: StringFilterInput | null;
  email?: StringFilterInput | null;
  program?: StringFilterInput | null;
  code?: CodeFiltersInput | null;
  status?: StringFilterInput | null;
  validationErrorMesssage?: StringFilterInput | null;
  date?: DateFilterInput | null;
  pdfFileName?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (KzCertFiltersInput | null)[] | null;
  or?: (KzCertFiltersInput | null)[] | null;
  not?: KzCertFiltersInput | null;
}

export interface KzLossFiltersInput {
  id?: IDFilterInput | null;
  certNumber?: StringFilterInput | null;
  certDate?: DateFilterInput | null;
  name?: StringFilterInput | null;
  birthDate?: DateFilterInput | null;
  message?: StringFilterInput | null;
  email?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (KzLossFiltersInput | null)[] | null;
  or?: (KzLossFiltersInput | null)[] | null;
  not?: KzLossFiltersInput | null;
}

export interface PaginationArg {
  page?: number | null;
  pageSize?: number | null;
  start?: number | null;
  limit?: number | null;
}

export interface RefundRequestFiltersInput {
  id?: IDFilterInput | null;
  status?: StringFilterInput | null;
  requestPdfFileUrl?: StringFilterInput | null;
  requestData?: JSONFilterInput | null;
  validationErrorMessage?: StringFilterInput | null;
  sender?: StringFilterInput | null;
  template?: StringFilterInput | null;
  createdAt?: DateTimeFilterInput | null;
  updatedAt?: DateTimeFilterInput | null;
  and?: (RefundRequestFiltersInput | null)[] | null;
  or?: (RefundRequestFiltersInput | null)[] | null;
  not?: RefundRequestFiltersInput | null;
}

export interface StringFilterInput {
  and?: (string | null)[] | null;
  or?: (string | null)[] | null;
  not?: StringFilterInput | null;
  eq?: string | null;
  ne?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  contains?: string | null;
  notContains?: string | null;
  containsi?: string | null;
  notContainsi?: string | null;
  gt?: string | null;
  gte?: string | null;
  lt?: string | null;
  lte?: string | null;
  null?: boolean | null;
  notNull?: boolean | null;
  in?: (string | null)[] | null;
  notIn?: (string | null)[] | null;
  between?: (string | null)[] | null;
}

export interface UsersPermissionsLoginInput {
  identifier: string;
  password: string;
  provider: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
