import React, {FC} from 'react';
import {Avatar, Dropdown, Menu, Space, Typography} from 'antd';
import {LogoutOutlined} from '@ant-design/icons';
import logo from '../../images/sk-logo-white.svg';
import {Link} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import {INDEX, LOGOUT} from '../../routes';

import './CrmHeader.scss';

const GET_ME = gql`
    query getMe {
        me {
            id
            email
            username
            role {
                id
                name
            }
        }
    }
`;

const CrmHeader: FC = props => {
  const { data } = useQuery(GET_ME);

  const userMenu = (
    <Menu>
      <Menu.Item>
        <Link to={LOGOUT}><LogoutOutlined/> Выйти</Link>
      </Menu.Item>
    </Menu>
  );

  const username = data ? data.me.username : '';

  return (
    <div className="crm-header-root">
      <div className="crm-header">
        <Space size={0}>
          <Link to={INDEX}><img src={logo} className="crm-header-logo" alt="logo"/></Link>
        </Space>

        <Space size={0}>
          <Dropdown overlay={userMenu} trigger={[ 'click' ]}>
            <Space className="crm-header-dropdown-content">

              <Typography className="crm-header-username">
                {username}
              </Typography>
              <Avatar size="small" className="crm-header-avatar">
                {username.charAt(0).toUpperCase()}{username.charAt(1).toUpperCase()}
              </Avatar>
            </Space>
          </Dropdown>
        </Space>
      </div>
    </div>
  );
};

export default CrmHeader;
