import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Col, Empty, Layout, message, Row, Skeleton, Table, TablePaginationConfig} from 'antd';
import map from 'lodash/map';
import dayjs from 'dayjs';
import filter from 'lodash/filter';
import {gql, useQuery} from '@apollo/client';
import CrmHeader from '../../components/CrmHeader/CrmHeader';
import useQueryParams from '../../hooks/useQueryParams';
import {getEmails, getEmailsVariables} from './__generated__/getEmails';
import {ENUM_RECORD_SENDSTATUS} from '../../__generated__/global-types';
import {useNavigate} from 'react-router-dom';
import {FILE_RECORDS, INDEX} from '../../routes';
import {GetComponentProps} from 'rc-table/lib/interface';
import NavHeader from '../../components/NavHeader';


const { Content } = Layout;

const GET_EMAILS = gql`
    query getEmails($page: Int!, $pageSize: Int!) {
        emailToParses(pagination: {page: $page, pageSize: $pageSize}, sort: "id:DESC" ) {
            meta {
                pagination {
                    total
                }
            }
            data {
                id
                attributes {
                    filename
                    originalFilename
                    createdAt
                    status
                    records (pagination: {limit: 999999999}) {
                        data {
                            id
                            attributes {
                                sendStatus
                            }
                        }
                    }
                }
            }
        }
    }
`;

const columns = [
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Название файла',
    dataIndex: 'originalFilename',
    key: 'originalFilename',
  },
  {
    title: 'Статистика отправки',
    dataIndex: 'stat',
    key: 'stat',
  },
  {
    title: '',
    dataIndex: 'successPercent',
    key: 'successPercent',
  },
];

interface TableRowItemData {
  key: string,
  createdAt: string,
  originalFilename: string,
  stat: string,
  successPercent: string,
}

const IndexPage: FC = () => {
  const pageSize = 50;
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') || '', 10) || 1;
  const navigate = useNavigate();

  const { loading, error, data } = useQuery<getEmails, getEmailsVariables>(
    GET_EMAILS,
    { variables: { page, pageSize } },
  );

  const total = data?.emailToParses?.meta.pagination.total || 0;

  if (error) {
    void message.error(error.message);
  }

  const dataSource: TableRowItemData[] = map(data?.emailToParses?.data, (item) => {
    const records = item.attributes?.records?.data;
    const totalCount = records ? records.length : 0;
    const successCount = filter(
      records,
      (record) => {
        return record.attributes?.sendStatus === ENUM_RECORD_SENDSTATUS.DELIVERED
          || record.attributes?.sendStatus === ENUM_RECORD_SENDSTATUS.PENDING_STATUS;
      },
    ).length;

    const stat = `${successCount}/${totalCount}`;
    const successPercent = totalCount > 0 ? `${Math.ceil((successCount / totalCount) * 100)}%` : '0%';

    return {
      key: item.id || '?',
      createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY'),
      originalFilename: item.attributes?.originalFilename || '?',
      stat: stat,
      successPercent,
    };
  });

  const onPageChange = (pagination: TablePaginationConfig) => {
    navigate(`${INDEX}?page=${pagination.current}`);
  };

  const onRow: GetComponentProps<TableRowItemData> = (record, rowIndex) => {
    return {
      onClick: () => {
        const id = record.key;
        navigate(`${FILE_RECORDS}/${id}`);
      },
    };
  };

  return (
    <Layout>
      <CrmHeader/>
      <Content className="main-content">
        <Row>
          <Col span={24}>
           <NavHeader />
          </Col>
          <Col span={24}>
            {!data && loading && (
              <React.Fragment>
                <Skeleton.Input/>
                <Skeleton.Input/>
                <Skeleton.Input/>
                <Skeleton.Input/>
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              size="middle"
              loading={loading}
              columns={columns}
              onRow={onRow}
              pagination={{
                position: [ 'bottomLeft' ],
                pageSize,
                current: page,
                total,
              }}
              onChange={onPageChange}
              dataSource={loading ? [] : dataSource}
              locale={{
                emptyText: loading ? <Skeleton active={true}/> : <Empty description="нет данных"/>,
              }}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default observer(IndexPage);



