import XLSX from 'xlsx';
import dayjs from 'dayjs';
import {getCert_kzCerts} from "./__generated__/getCert";

interface ExportToExcelArgs {
  data: getCert_kzCerts['data'],
  fileName: string,
}

function exportToExcel(args: ExportToExcelArgs) {
  const {
    fileName,
    data,
  } = args;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([{}]);

  const headers = [
    [
      'Дата',
      'Номер',
      'Программа',
      'Фамилия, Имя',
      'Дата рождения',
      'Паспорт',
      'Email',
      'Телефон',
      'Ссылка на сертификат',
    ],
  ];

  const colsSizes = [
    { width: 15 },
    { width: 20 },
    { width: 15 },
    { width: 30 },
    { width: 15 },
    { width: 15 },
    { width: 20 },
    { width: 20 },
    { width: 25 },
  ];

  const rows = data
    .map((item) => {

      let program = ''
      switch (item.attributes?.program) {
        case 'program50': program = '50 000 ₸'; break;
        case 'program80': program = '80 000 ₸'; break;
        case 'program150': program = '150 000 ₸'; break;
      }

      return {
        date: dayjs(item.attributes?.date).format('DD.MM.YYYY'),
        number: item.attributes?.number,
        program: program,
        name: item.attributes?.name,
        birthDate: dayjs(item.attributes?.birthDate).format('DD.MM.YYYY'),
        passport: item.attributes?.passport,
        email: item.attributes?.email,
        phone: item.attributes?.phone,
        pdfFileUrl: item.attributes?.pdfFileName
      };
    });

  XLSX.utils.sheet_add_json(ws, headers, { origin: 'A1', skipHeader: true });
  XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
  ws['!cols'] = colsSizes;

  XLSX.utils.book_append_sheet(wb, ws, 'Коды');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export default exportToExcel;
