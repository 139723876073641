import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {message, Select} from 'antd';
import {allowedStatusFlowFromStatus, statusNamesMap} from '../../dataMappers';
import {ENUM_REFUNDREQUEST_STATUS} from '../../__generated__/global-types';
import {gql, useMutation} from '@apollo/client';
import {changeStatus, changeStatusVariables} from './__generated__/changeStatus';

interface IProps {
  id: string,
  status: ENUM_REFUNDREQUEST_STATUS,
}

const CHANGE_STATUS = gql`
    mutation changeStatus($id: ID!, $status: ENUM_REFUNDREQUEST_STATUS!) {
        updateRefundRequest(id: $id, data: { status: $status}) {
            data {
                id
                attributes {
                    createdAt
                    requestData
                    requestPdfFileUrl
                    status
                    validationErrorMessage
                }
            }
        }
    }
`;

const StatusChanger: FC<IProps> = ({ status, id }) => {
  const toStatuses = allowedStatusFlowFromStatus[status];

  const [updateStatus, { data, loading, error }] = useMutation<changeStatus, changeStatusVariables>(
    CHANGE_STATUS,
  );

  useEffect(() => {
    if (error) {
      message.error('Ошибка при смене статуса');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      message.success('Статус изменён');
    }
  }, [data]);

  const options: {
    value: ENUM_REFUNDREQUEST_STATUS,
    label: string,
  }[] = useMemo(() => {
    const values = toStatuses.map((toStatus) => ({
      value: toStatus,
      label: statusNamesMap[toStatus],
    }));

    return [
      {
        value: status,
        label: statusNamesMap[status],
      },
      ...values,
    ];
  }, [status, toStatuses]);

  const onChange = useCallback((value: ENUM_REFUNDREQUEST_STATUS) => {
    return updateStatus({
      variables: {
        id,
        status: value,
      },
    });
  }, [id, updateStatus]);

  return (
    <Select
      disabled={loading}
      loading={loading}
      value={status}
      dropdownMatchSelectWidth={false}
      defaultValue={status}
      allowClear={false}
      options={options}
      onChange={onChange}
    />
  );
};

export default StatusChanger;
