import XLSX from 'xlsx';
import {getRequests_refundRequests} from './__generated__/getRequests';
import {ENUM_REFUNDREQUEST_STATUS} from '../../__generated__/global-types';
import {getApiBase} from '../../env';
import dayjs from 'dayjs';
import {statusNamesMap} from '../../dataMappers';

interface ExportToExcelArgs {
  data: getRequests_refundRequests['data'],
  fileName: string,
}

function exportToExcel(args: ExportToExcelArgs) {
  const {
    fileName,
    data,
  } = args;

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([{}]);

  const emptyData = {
    FIO: '',
    Passport: '',
    Phone: '',
    Email: '',
    Certificate_Number: '',
    Certificate_Date: '',
    Bank_Name: '',
    Bank_Correspondent: '',
    Bank_Number: '',
    Bank_Account: '',
  };

  const headers = [
    [
      'Дата создания',
      'Файл заявления',
      'Статус',
      'Ошибка',
      'ФИО',
      'Паспорт',
      'Телефон',
      'Email',
      'Сертификат',
      'Дата сертификата',
      'Название банка',
      'Кор. счёт.',
      'БИК',
      'Номер счёта',
    ],
  ];

  const colsSizes = [
    { width: 15 },
    { width: 70 },
    { width: 45 },
    { width: 40 },
    { width: 30 },
    { width: 20 },
    { width: 20 },
    { width: 25 },
    { width: 20 },
    { width: 20 },
    { width: 50 },
    { width: 25 },
    { width: 15 },
    { width: 25 },
  ];

  const rows = data
    .map((item) => {
      const requestData = !item.attributes?.requestData ? emptyData : { ...emptyData, ...item.attributes?.requestData };

      return {
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY'),
        requestPdfFileUrl: !item.attributes?.requestPdfFileUrl ? 'нет' : `${getApiBase()}/uploads/pdf/${item.attributes.requestPdfFileUrl}`,
        status: item.attributes?.status ? statusNamesMap[item.attributes.status] : statusNamesMap[ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7],
        validationErrorMessage: item.attributes?.status === ENUM_REFUNDREQUEST_STATUS.GEN_ERROR_7 && item.attributes.validationErrorMessage ? item.attributes.validationErrorMessage : 'нет',
        ...requestData,
      };
    });

  XLSX.utils.sheet_add_json(ws, headers, { origin: 'A1', skipHeader: true });
  XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
  ws['!cols'] = colsSizes;

  XLSX.utils.book_append_sheet(wb, ws, 'Заявления');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export default exportToExcel;
